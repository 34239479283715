<template>
  <div>
    新闻
    <div>111111111111111111111111111111</div>
    <h1>vvvvvvvvvvvvvvvvvvvvvvvvv</h1>
  </div>
</template>

<script>
console.log('newsInfonewsInfonewsInfonewsInfonewsInfonewsInfo2222222222')
export default {
  name: "newsInfo",
  mounted() {
    console.log('newsInfonewsInfonewsInfonewsInfonewsInfonewsInfonewsInfo2222222222')
  }
}
</script>

<style scoped>

</style>